import React from 'react';
import { FaCalendar } from 'react-icons/fa';

function DetailsAsset({ details }) {
  console.log('Rendering DetailsAsset with details:', details); // Add this log
  return (
    <div className="details-asset">
      <div className="details-overlay">
        <h2>{details.title}</h2>
        <p>{details.long_description}</p>
        <p className="price">Price: ${details.price}</p>
        <div className="available-dates">
          <FaCalendar />
          <span>Available Dates:</span>
          <ul>
            {details.available_dates.map((date, index) => (
              <li key={index}>{date}</li>
            ))}
          </ul>
        </div>
        <a href={details.booking_link} className="booking-button" target="_blank" rel="noopener noreferrer">
          Book Now
        </a>
      </div>
    </div>
  );
}

export default DetailsAsset;