import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/BookingFlow.css';

function BookingFlow() {
  const { id } = useParams();
  const navigate = useNavigate();
  const destination = useSelector(state => 
    state.destinations.find(dest => dest.id === parseInt(id))
  );
  const [step, setStep] = useState(1);

  if (!destination) {
    return <div>Loading...</div>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement booking logic here
    alert('Booking successful!');
    navigate('/');
  };

  return (
    <div className="booking-flow">
      <h2>Book Your Trip to {destination.title}</h2>
      {step === 1 && (
        <div>
          <h3>Step 1: Select Dates</h3>
          {/* Implement date selection */}
          <button onClick={() => setStep(2)}>Next</button>
        </div>
      )}
      {step === 2 && (
        <div>
          <h3>Step 2: Enter Details</h3>
          <form onSubmit={handleSubmit}>
            {/* Implement form fields */}
            <button type="submit">Complete Booking</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default BookingFlow;