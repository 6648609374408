import React from 'react';
import { Routes, Route } from 'react-router-dom';
import VideoFeed from './components/VideoFeed';
import DetailedView from './components/DetailedView';
import BookingFlow from './components/BookingFlow';
import './styles/App.css';

function App() {
  return (
    <div className="App">
      {/* <h1>Travel Destination Showcase</h1> */}
      <Routes>
        <Route path="/" element={<VideoFeed />} />
        <Route path="/details/:id" element={<DetailedView />} />
        <Route path="/book/:id" element={<BookingFlow />} />
      </Routes>
      <div>
        {/* Fallback content */}
        {/* If you see this, the routes are not rendering properly. */}
      </div>
    </div>
  );
}

export default App;
