import destinationsData from '../content/destinations.json';

export const FETCH_DESTINATIONS = 'FETCH_DESTINATIONS';

export const fetchDestinations = () => {
  return (dispatch) => {
    // Simulating API call with local data
    console.log('Fetching destinations...');
    setTimeout(() => {
      console.log('Destinations fetched:', destinationsData);
      dispatch({
        type: FETCH_DESTINATIONS,
        payload: destinationsData
      });
    }, 500);
  };
};