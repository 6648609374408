import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // Change this line
import rootReducer from './reducers';

const logger = store => next => action => {
  console.log('Dispatching:', action);
  let result = next(action);
  console.log('Next state:', store.getState());
  return result;
};

const store = createStore(rootReducer, applyMiddleware(thunk, logger));

export default store;