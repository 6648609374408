import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/DetailedView.css';

function DetailedView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const destination = useSelector(state => 
    state.destinations.find(dest => dest.id === parseInt(id))
  );

  if (!destination) {
    return <div>Loading...</div>;
  }

  return (
    <div className="detailed-view">
      <button onClick={() => navigate('/')}>Back</button>
      <h1>{destination.title}</h1>
      <p>{destination.long_description}</p>
      <div className="image-gallery">
        {destination.images.map((image, index) => (
          <img key={index} src={image} alt={`${destination.title} - ${index + 1}`} />
        ))}
      </div>
      <div className="booking-info">
        <p>Price: ${destination.price}</p>
        <p>Available dates: {destination.available_dates.join(', ')}</p>
        <button onClick={() => navigate(`/book/${destination.id}`)}>Book Now</button>
      </div>
    </div>
  );
}

export default DetailedView;