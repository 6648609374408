import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { fetchDestinations } from "../redux/actions";
import {
  FaReply,
  FaShare,
  FaForward,
  FaCalendarCheck,
  FaChevronLeft,
  FaChevronRight,
  FaVolumeUp,
  FaVolumeMute,
  FaPlay,
} from "react-icons/fa";
import "../styles/VideoFeed.css";
import DetailsAsset from "./DetailsAsset";

function MP4Player({ src, isVisible, isMuted = true, onError, isLoaded }) {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const playAttemptRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = src;
      videoRef.current.load();
      console.log("Video source set and loading started:", src);
    }
    return () => {
      if (playAttemptRef.current) {
        clearTimeout(playAttemptRef.current);
      }
    };
  }, [src]);

  useEffect(() => {
    let isMounted = true;
    if (isVisible && videoRef.current && isLoaded) {
      videoRef.current.muted = true;
      const attemptPlay = () => {
        if (!isMounted) return;
        videoRef.current
          .play()
          .then(() => {
            if (!isMounted) return;
            console.log("Video played successfully");
            if (!isMuted) {
              videoRef.current.muted = false;
            }
          })
          .catch((e) => {
            if (!isMounted) return;
            console.error("Error playing video:", e);
            if (
              e.name === "NotSupportedError" ||
              e.message.includes("PIPELINE_ERROR_READ")
            ) {
              setError(`Video format not supported or file is corrupted`);
              if (onError) onError(e);
            } else if (e.name !== "AbortError") {
              setError(`Failed to play video: ${e.message}`);
              if (onError) onError(e);
            } else {
              console.log("Play attempt aborted, retrying...");
              playAttemptRef.current = setTimeout(attemptPlay, 1000);
            }
          });
      };
      attemptPlay();
    } else if (videoRef.current) {
      videoRef.current.pause();
    }
    return () => {
      isMounted = false;
      if (playAttemptRef.current) {
        clearTimeout(playAttemptRef.current);
      }
    };
  }, [isVisible, isMuted, onError, isLoaded]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  if (error) {
    return <div className="video-error">Error: {error}</div>;
  }

  return (
    <video
      ref={videoRef}
      className={`mp4-player ${isLoaded ? "loaded" : ""}`}
      loop
      playsInline
      muted
      autoPlay={false}
      onError={(e) => {
        console.error("Video error:", e, e.target.error);
        setError(
          `Failed to load video: ${e.target.error?.message || "Unknown error"}`
        );
        if (onError) onError(e);
      }}
      onLoadedMetadata={() => console.log("Video metadata loaded")}
      onCanPlay={() => console.log("Video can play")}
    />
  );
}

const AssetCarousel = React.memo(function AssetCarousel({
  assets,
  onAssetSelect,
  selectedIndex,
}) {
  const carouselRef = useRef(null);
  const [showButtons, setShowButtons] = useState(window.innerWidth > 768);
  const [loadedAssets, setLoadedAssets] = useState(assets.map(() => false));

  useEffect(() => {
    const handleResize = () => {
      setShowButtons(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToSelectedAsset = useCallback(() => {
    if (carouselRef.current && assets.length > 0) {
      const assetWidth = 160; // Width of each asset preview
      const containerWidth = carouselRef.current.offsetWidth;
      const scrollPosition =
        selectedIndex * assetWidth - containerWidth / 2 + assetWidth / 2;
      carouselRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [selectedIndex, assets.length]);

  useEffect(() => {
    scrollToSelectedAsset();
  }, [selectedIndex, scrollToSelectedAsset]);

  const moveAssetLeft = () => {
    const newIndex = (selectedIndex - 1 + assets.length) % assets.length;
    onAssetSelect(assets[newIndex], newIndex);
  };

  const moveAssetRight = () => {
    const newIndex = (selectedIndex + 1) % assets.length;
    onAssetSelect(assets[newIndex], newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: moveAssetRight,
    onSwipedRight: moveAssetLeft,
  });

  const handleAssetLoad = (index) => {
    setLoadedAssets((prev) => {
      const newLoadedAssets = [...prev];
      newLoadedAssets[index] = true;
      return newLoadedAssets;
    });
  };

  const handleAssetError = (index) => {
    setLoadedAssets((prev) => {
      const newLoadedAssets = [...prev];
      newLoadedAssets[index] = false;
      return newLoadedAssets;
    });
  };

  return (
    <div className="asset-carousel-container">
      {showButtons && (
        <button
          onClick={moveAssetLeft}
          className="carousel-button left"
          aria-label="Previous asset"
        >
          <FaChevronLeft />
        </button>
      )}
      <div className="asset-carousel" ref={carouselRef} {...handlers}>
        {assets.map((asset, index) => (
          <div
            key={index}
            className={`asset-preview ${
              index === selectedIndex ? "selected" : ""
            }`}
            onClick={() => onAssetSelect(asset, index)}
          >
            {asset.type === "details" ? (
              <DetailsAsset details={asset.details} />
            ) : asset.type === "video" ? (
              <>
                <video
                  src={asset.src}
                  muted
                  loop
                  playsInline
                  onLoadedData={() => handleAssetLoad(index)}
                  onError={() => handleAssetError(index)}
                />
                <div className="video-icon">
                  <FaPlay />
                </div>
              </>
            ) : (
              <img
                src={asset.src}
                alt={`Asset ${index + 1}`}
                onLoad={() => handleAssetLoad(index)}
                onError={() => handleAssetError(index)}
              />
            )}
            {!loadedAssets[index] && <div>Loading...</div>}
          </div>
        ))}
      </div>
      {showButtons && (
        <button
          onClick={moveAssetRight}
          className="carousel-button right"
          aria-label="Next asset"
        >
          <FaChevronRight />
        </button>
      )}
    </div>
  );
});

function useAssetPreloader(assets) {
  const [loadedAssets, setLoadedAssets] = useState({});

  useEffect(() => {
    console.log("Starting asset preload for:", assets.length, "assets");
    const newLoadedAssets = {};
    const loadPromises = assets.map(
      (asset) =>
        new Promise((resolve, reject) => {
          if (asset.type === "image") {
            const img = new Image();
            img.onload = () => {
              newLoadedAssets[asset.src] = true;
              resolve();
            };
            img.onerror = reject;
            img.src = asset.src;
          } else if (asset.type === "video") {
            const video = document.createElement("video");
            video.onloadedmetadata = () => {
              newLoadedAssets[asset.src] = true;
              resolve();
            };
            video.onerror = reject;
            video.src = asset.src;
            video.preload = "metadata";
          }
        })
    );

    Promise.all(loadPromises)
      .then(() => {
        console.log("All assets preloaded:", newLoadedAssets);
        setLoadedAssets((prevLoadedAssets) => ({
          ...prevLoadedAssets,
          ...newLoadedAssets,
        }));
      })
      .catch((error) => {
        console.error("Error preloading assets:", error);
      });

    return () => {
      assets.forEach((asset) => {
        if (asset.type === "image") {
          const img = new Image();
          img.src = "";
        } else if (asset.type === "video") {
          const video = document.createElement("video");
          video.src = "";
        }
      });
    };
  }, [assets]); // Only depend on assets

  return loadedAssets;
}

function AssetPreloader({ assets }) {
  useAssetPreloader(assets);
  return null;
}

function VideoFeed() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const destinations = useSelector((state) => state.destinations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [backgroundKey, setBackgroundKey] = useState(0);
  const [videoError, setVideoError] = useState(null);
  const [currentDestinationLoaded, setCurrentDestinationLoaded] =
    useState(false);
  const [assetsToPreload, setAssetsToPreload] = useState([]);

  useEffect(() => {
    console.log("VideoFeed mounted, dispatching fetchDestinations");
    dispatch(fetchDestinations());
  }, [dispatch]);

  useEffect(() => {
    if (destinations.length > 0) {
      const currentDestination = destinations[currentIndex];

      // First, preload current destination's primary assets
      const primaryAssetsToPreload = [
        { type: "video", src: currentDestination.primary_video },
        { type: "image", src: currentDestination.primary_image },
      ].filter((asset) => asset.src);

      setAssetsToPreload(primaryAssetsToPreload);
    }
  }, [currentIndex, destinations]);

  useEffect(() => {
    if (currentDestinationLoaded && destinations.length > 0) {
      const currentDestination = destinations[currentIndex];
      const nextIndex = (currentIndex + 1) % destinations.length;
      const nextDestination = destinations[nextIndex];

      // Then, preload other assets including next destination
      const secondaryAssetsToPreload = [
        ...(currentDestination.videos || []).map((src) => ({
          type: "video",
          src,
        })),
        ...(currentDestination.images || []).map((src) => ({
          type: "image",
          src,
        })),
        { type: "video", src: nextDestination.primary_video },
        { type: "image", src: nextDestination.primary_image },
        ...(nextDestination.videos || []).map((src) => ({
          type: "video",
          src,
        })),
        ...(nextDestination.images || []).map((src) => ({
          type: "image",
          src,
        })),
      ].filter((asset) => asset.src);

      setAssetsToPreload((prevAssets) => [
        ...prevAssets,
        ...secondaryAssetsToPreload,
      ]);
    }
  }, [currentDestinationLoaded, currentIndex, destinations]);

  const loadedAssets = useAssetPreloader(assetsToPreload);

  useEffect(() => {
    if (
      loadedAssets[destinations[currentIndex]?.primary_video] &&
      loadedAssets[destinations[currentIndex]?.primary_image]
    ) {
      setCurrentDestinationLoaded(true);
    }
  }, [loadedAssets, destinations, currentIndex]);

  const currentDestination = destinations[currentIndex];

  const uniqueCarouselAssets = useMemo(() => {
    console.log(
      "Creating uniqueCarouselAssets, currentDestination:",
      currentDestination
    );
    if (!currentDestination) {
      console.log("No current destination, returning empty array");
      return [];
    }

    console.log("Creating uniqueCarouselAssets for:", currentDestination.title);
    const carouselAssets = [
      // 1. Primary video
      { type: "video", src: currentDestination.primary_video },
      // 2. Primary image
      ...(currentDestination.primary_image
        ? [{ type: "image", src: currentDestination.primary_image }]
        : []),
      // 3. All other videos
      ...(currentDestination.videos
        ? currentDestination.videos
            .filter((video) => video !== currentDestination.primary_video)
            .map((video) => ({ type: "video", src: video }))
        : []),
      // 4. All other images
      ...(currentDestination.images
        ? currentDestination.images
            .filter((image) => image !== currentDestination.primary_image)
            .map((image) => ({ type: "image", src: image }))
        : []),
    ].filter((asset) => asset.src); // Remove any assets with undefined src

    console.log("Carousel assets before deduplication:", carouselAssets);

    // Add the new details asset
    carouselAssets.push({
      type: "details",
      src: currentDestination.primary_image,
      details: currentDestination,
    });
    console.log("Carousel assets including details:", carouselAssets);

    // Remove duplicate assets based on src
    const uniqueAssets = carouselAssets.reduce((acc, current) => {
      const x = acc.find((item) => item.src === current.src);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    console.log("Final uniqueCarouselAssets:", uniqueAssets);
    return uniqueAssets;
  }, [currentDestination]);

  const handleAssetChange = useCallback(
    (newIndex, isDestinationChange = false) => {
      setIsLoading(true);
      setBackgroundKey((prev) => prev + 1);

      if (isDestinationChange) {
        setCurrentIndex(newIndex);
        setSelectedAssetIndex(0);
        setSelectedAsset(null);
      } else {
        setSelectedAssetIndex(newIndex);
        setSelectedAsset(uniqueCarouselAssets[newIndex]);
      }

      setTimeout(() => setIsLoading(false), 100);
    },
    [uniqueCarouselAssets]
  );

  useEffect(() => {
    if (uniqueCarouselAssets.length > 0) {
      setSelectedAsset(uniqueCarouselAssets[selectedAssetIndex]);
    }
  }, [selectedAssetIndex, uniqueCarouselAssets]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case "ArrowUp":
          handleAssetChange(Math.max(currentIndex - 1, 0), true);
          break;
        case "ArrowDown":
          handleAssetChange(
            Math.min(currentIndex + 1, destinations.length - 1),
            true
          );
          break;
        case "ArrowLeft":
          handleAssetChange(
            (selectedAssetIndex - 1 + uniqueCarouselAssets.length) %
              uniqueCarouselAssets.length
          );
          break;
        case "ArrowRight":
          handleAssetChange(
            (selectedAssetIndex + 1) % uniqueCarouselAssets.length
          );
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    handleAssetChange,
    currentIndex,
    destinations.length,
    selectedAssetIndex,
    uniqueCarouselAssets.length,
  ]);

  const handlers = useSwipeable({
    onSwipedUp: () =>
      handleAssetChange(
        Math.min(currentIndex + 1, destinations.length - 1),
        true
      ),
    onSwipedDown: () => handleAssetChange(Math.max(currentIndex - 1, 0), true),
    onSwipedLeft: () =>
      handleAssetChange((selectedAssetIndex + 1) % uniqueCarouselAssets.length),
    onSwipedRight: () =>
      handleAssetChange(
        (selectedAssetIndex - 1 + uniqueCarouselAssets.length) %
          uniqueCarouselAssets.length
      ),
  });

  const renderBackground = () => {
    const assetToRender = selectedAsset || {
      type: "video",
      src: currentDestination?.primary_video,
    };
    // console.log('Rendering background:', assetToRender);

    if (videoError && assetToRender.type === "video") {
      // Fallback to primary image if available
      const fallbackImage = currentDestination?.primary_image;
      if (fallbackImage) {
        return (
          <div
            key={backgroundKey}
            className={`background-container ${
              isLoading ? "fade-out" : "fade-in"
            }`}
          >
            <img
              src={fallbackImage}
              alt="Fallback asset"
              className="background-image"
              onError={() => setError("Failed to load fallback image")}
            />
          </div>
        );
      }
    }

    return (
      <div
        key={backgroundKey}
        className={`background-container ${isLoading ? "fade-out" : "fade-in"}`}
      >
        {assetToRender.type === "video" ? (
          <MP4Player
            src={assetToRender.src}
            isVisible={true}
            isMuted={!isSoundOn}
            onError={(e) => {
              console.error("Failed to play video:", e);
              setVideoError(e.message);
              setSelectedAsset(null);
            }}
            isLoaded={isAssetLoaded(assetToRender.src)}
          />
        ) : (
          <img
            src={assetToRender.src}
            alt="Selected asset"
            className={`background-image ${
              isAssetLoaded(assetToRender.src) ? "loaded" : ""
            }`}
            onError={() => setSelectedAsset(null)}
          />
        )}
      </div>
    );
  };

  const toggleSound = () => {
    setIsSoundOn((prev) => !prev);
  };

  const isAssetLoaded = useCallback(
    (src) => {
      return loadedAssets[src] === true;
    },
    [loadedAssets]
  );

  // Debug: Log assetsToPreload and loadedAssets
  useEffect(() => {
    console.log("Assets to preload:", assetsToPreload);
    console.log("Loaded assets:", loadedAssets);
    // Add these to window properties for easy access in console
    window.debugAssetsToPreload = assetsToPreload;
    window.debugLoadedAssets = loadedAssets;
  }, [assetsToPreload, loadedAssets]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (destinations.length === 0) {
    return <div>Loading... (No destinations available)</div>;
  }

  console.log("Current destination:", currentDestination);

  return (
    <div className={`video-feed ${isLoading ? "loading" : ""}`} {...handlers}>
      <AssetPreloader assets={assetsToPreload} />
      {renderBackground()}
      <div className="overlay">
        {videoError && (
          <div className="error-message">
            Unable to play video. Please try another asset.
          </div>
        )}
        <div className="content top">
          <h2>{currentDestination?.title}</h2>
          <p>{currentDestination?.short_description}</p>
        </div>
        <div className="interaction-buttons">
          <button
            onClick={() => {
              /* Implement reply */
            }}
          >
            <FaReply />
          </button>
          <button
            onClick={() => {
              /* Implement forward */
            }}
          >
            <FaForward />
          </button>
          <button
            onClick={() => {
              /* Implement share */
            }}
          >
            <FaShare />
          </button>
          <button onClick={() => navigate(`/book/${currentDestination?.id}`)}>
            <FaCalendarCheck />
          </button>
          <button onClick={toggleSound}>
            {isSoundOn ? <FaVolumeUp /> : <FaVolumeMute />}
          </button>
        </div>
        <div className="content bottom">
          {uniqueCarouselAssets.length > 0 ? (
            <AssetCarousel
              assets={uniqueCarouselAssets}
              onAssetSelect={(asset, index) => handleAssetChange(index)}
              selectedIndex={selectedAssetIndex}
            />
          ) : (
            <div>No assets available for this destination</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoFeed;
