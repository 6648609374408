import { FETCH_DESTINATIONS } from './actions';

const initialState = {
  destinations: []
};

const rootReducer = (state = initialState, action) => {
  console.log('Reducer called with action:', action.type);
  switch (action.type) {
    case FETCH_DESTINATIONS:
      console.log('FETCH_DESTINATIONS payload:', action.payload);
      return {
        ...state,
        destinations: action.payload
      };
    default:
      return state;
  }
};

export default rootReducer;